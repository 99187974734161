import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { H2, H3, B1, Tile } from '../components/page-elements';
import Container from '../components/container';
import FormInput from '../components/formInput';
import Layout from '../components/layout';

import SEO from '../components/seo';

import MailIcon from '../images/svg/mail.inline.svg';

// Styled Components
const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing[20]};
  text-align: center;

  @media (min-width: 48em) {
    margin-top: ${({ theme }) => theme.spacing[48]};
  }
`;

const FormContainer = styled(Tile)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.secondary[800]};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23f1f7f8' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  box-shadow: ${({ theme }) => theme.boxShadow.lg};
  margin: 0 auto ${({ theme }) => theme.spacing[32]} auto;
  padding: ${({ theme }) => theme.spacing[16]}
    ${({ theme }) => theme.spacing[10]};
  text-align: center;
  max-width: 1100px;

  @media (min-width: 37.5em) {
    padding: ${({ theme }) => theme.spacing[16]};
  }

  & > form {
    width: 100%;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing[10]};
  > * {
    &:first-child {
      margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
  }
  @media (min-width: 64em) {
    flex-wrap: unset;
    > * {
      &:first-child {
        margin-right: ${({ theme }) => theme.spacing[6]};
        margin-bottom: 0;
      }
    }
  }
`;

const SubmitButton = styled(B1)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.spacing[10]};
  width: 100%;

  @media (min-width: 37.5em) {
    width: unset;
  }

  & > svg {
    height: ${({ theme }) => theme.fontSize.xl};
    width: ${({ theme }) => theme.fontSize.xl};
    margin-left: ${({ theme }) => theme.spacing[4]};
    fill: white;
  }
`;
const Contact = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, `C'mon your name is longer than that!`)
      .required('Required'),
    email: Yup.string()
      .email(`Must be a valid email.`)
      .required(`Required`),
    message: Yup.string()
      .min(3, `Too short!`)
      .required(`Let us know how we can help!`),
  });

  return (
    <>
      <Layout>
        <SEO title='Contact' />
        <StyledContainer>
          <H2>Get in touch</H2>
          <H3>We're always around for a chat.</H3>
          <FormContainer>
            <Formik
              initialValues={{
                'form-name': `Contact Form`,
                'bot-field': ``,
                name: ``,
                email: ``,
                message: ``,
              }}
              validationSchema={validationSchema}
              // onSubmit={{}}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                isValid,
              }) => (
                <form
                  name='Contact Form'
                  method='POST'
                  data-netlify='true'
                  data-netlify-honeypot='bot-field'
                  action='/'
                >
                  {/* The `form-name` hidden field is required to support form
                submissions without JavaScript */}
                  <input type='hidden' name='form-name' value='Contact Form' />
                  <p hidden>
                    Don’t fill this out if you're a human:{' '}
                    <input name='bot-field' />
                  </p>
                  <FormRow>
                    <FormInput
                      name='name'
                      placeholder='Your name'
                      required
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      errorCase={!!errors.name && touched.name}
                      errorMsg={errors.name}
                    />
                    <FormInput
                      name='email'
                      placeholder='Your email'
                      required
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      errorCase={!!errors.email && touched.email}
                      errorMsg={errors.email}
                    />
                  </FormRow>
                  <FormInput
                    name='message'
                    placeholder='Your message'
                    multiline
                    required
                    value={values.message}
                    onChange={handleChange('message')}
                    onBlur={handleBlur('message')}
                    errorCase={!!errors.message && touched.message}
                    errorMsg={errors.message}
                  />
                  <SubmitButton>
                    Send Message
                    <MailIcon />
                  </SubmitButton>
                  {/* <SendMessageButton
                  type="submit"
                  // onClick={handleSubmit}
                  disabled={!isValid}
                >
                  <Row>
                    <p>Send Message</p>
                    <PaperPlane />
                  </Row>
                </SendMessageButton>
                <FormNote>
                  The Rubberduck team will get back to you within{' '}
                  <span>24 hours.</span>
                </FormNote> */}
                </form>
              )}
            </Formik>
          </FormContainer>
        </StyledContainer>
      </Layout>
    </>
  );
};

export default Contact;
