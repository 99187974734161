import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Styled Components
const InputContainer = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: ${props => (props.required ? `'*'` : `''`)};
    position: absolute;
    top: 25px;
    right: ${({ theme }) => theme.spacing[5]};
    width: 10px;
    height: 10px;
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.body};
  }

  @media (min-width: 37.5em) {
    &:after {
      right: ${({ theme }) => theme.spacing[10]};
    }
  }
`;

const InputStyles = css`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => theme.spacing[6]} ${({ theme }) => theme.spacing[6]};
  /* border: none; */
  border: ${props =>
    props.errorCase ? `1px solid red` : `1px solid transparent`};
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-family: ${({ theme }) => theme.font.body};
  transition: all 0.2s ease-in;
  box-shadow: ${({ theme }) => theme.boxShadow.md};

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary[500]};
  }

  &:focus {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.boxShadow.lg};

    outline: none;
  }

  @media (min-width: 37.5em) {
    padding-right: ${({ theme }) => theme.spacing[10]};
    padding-left: ${({ theme }) => theme.spacing[10]};
  }
`;

const Input = styled.input`
  ${InputStyles};
`;

const TextArea = styled.textarea`
  ${InputStyles};
  resize: none;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error[100]};
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: 3px;
  text-align: left;
`;

class FormInput extends Component {
  render() {
    const {
      required,
      multiline,
      placeholder,
      style,
      name,
      errorCase,
      errorMsg,
      ...props
    } = this.props;
    return (
      <InputContainer required={required} style={style}>
        {multiline ? (
          <TextArea
            name={name}
            rows="8"
            placeholder={placeholder}
            errorCase={errorCase}
            {...props}
          />
        ) : (
          <Input
            name={name}
            placeholder={placeholder}
            errorCase={errorCase}
            {...props}
          />
        )}
        {errorCase && <ErrorMessage>{errorMsg}</ErrorMessage>}
      </InputContainer>
    );
  }
}

FormInput.propTypes = {};

export default FormInput;
